import React from 'react'
import Grid from '@mui/material/Grid2';
import Header from '../../layout/Header'
import mpmOne from '../../../assets/Features/mpm-one.webp'
import mpmTwo from '../../../assets/Features/mpm-two.webp'
import { Box, Typography } from '@mui/material';

function MultiPersonMonitoring() {
  return (
    <>
    <Header title="Multi Person Monitoring"/>    

    <Grid container alignItems="center" direction="column">
        <Grid item container size={12}>
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${mpmOne})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${mpmTwo})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
        </Grid>

        <Grid item maxWidth="1110px">           
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5}>Multi person monitoring entails the observation of activities and performance across various individuals within a team or organization, offering a complete perspective on collective advancement.
            This strategy encourages collaboration by enabling team members to recognize each other's contributions, which enhances communication and teamwork.</Typography>            
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5} pb={5}>Leveraging technology for multi-person monitoring allows organizations to gain immediate insights into productivity levels, facilitating prompt adjustments to workflows and resource allocation. With in-depth analytics derived from multi person monitoring, managers can make well-informed decisions based on both individual and group performance metrics, thereby optimizing team dynamics.</Typography>
        </Grid>
    </Grid>
    </>
  )
}

export default MultiPersonMonitoring