import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import logo from '../../assets/smt-logo.webp'
import Grid from '@mui/material/Grid2';
import AnimatedButton from '../styled/NavButton';
import { Link, useNavigate } from "react-router-dom";

function ScrollTop(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex:999 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element, 
  window: PropTypes.func,
};

export default function Navbar(props) {
  const navigate = useNavigate();

  const handleClickFeatures = () =>{            
    navigate('/');
    
    setTimeout(() => {
      const anchor = document.querySelector('#features-section');
      if (anchor) {
        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100); 
  }
  
  return (
    <React.Fragment>      
      <AppBar color='#FFFFFF66' sx={{pl:5, pr:5, height:100, borderBottomRightRadius:30, borderBottomLeftRadius:30, backgroundColor: '#FFFFFF66', boxShadow: '0px 7px 14.7px 0px #00000040', backdropFilter: 'blur(40.5px)'}}>
        <Toolbar sx={{height:"100%"}}>
          <Grid container justifyContent="space-between" alignItems="center" width="100%">
            <Grid item size={3}>
              <Box component="img" onClick={() => (window.location.href = '/')} src={logo} width={228} height={68} sx={{cursor:"pointer"}}/>
            </Grid>
            
            <Grid item container size={9} spacing={1} justifyContent="flex-end">
              <AnimatedButton sx={{width:"250px"}}>Download App</AnimatedButton>     
              <AnimatedButton onClick={handleClickFeatures}>Features</AnimatedButton>                                     
              {/* <AnimatedButton >Pricing</AnimatedButton>              */}
              <Link to='/login'><AnimatedButton>Log In</AnimatedButton></Link>
              {/* <AnimatedButton >Register</AnimatedButton>               */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Box id="back-to-top-anchor"/>     
      
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>

    </React.Fragment>
  );
}
