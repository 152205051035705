import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@emotion/react';

const SpotButton = ({
  variant = 'contained', 
  color = 'primary', 
  size = 'medium', 
  onClick,
  disabled = false,
  loading = false, 
  children,
  sx = {},
  ...props
  
}) => {
  const theme = useTheme();
  return (
    <Button
      disableRipple
      variant={variant}
      color={color}
      size={size}
      onClick={onClick}
      disabled={disabled || loading}
      {...props}
      startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} 
      sx={{bgcolor:theme.palette.common.white, fontSize:"16px", borderRadius:"20px", ...sx}}
    >
      {children}
    </Button>
  );
};
export default SpotButton;
