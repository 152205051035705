import React from 'react'
import Grid from '@mui/material/Grid2';
import Header from '../../layout/Header'
import ppOne from '../../../assets/Features/pp-one.webp'
import ppTwo from '../../../assets/Features/pp-two.webp'
import { Box, Typography } from '@mui/material';

function ProjectProgress() {
  return (
    <>
    <Header title="Project Progress Overview"/>    

    <Grid container alignItems="center" direction="column">
        <Grid item container size={12}>
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${ppOne})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${ppTwo})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
        </Grid>

        <Grid item maxWidth="1110px">           
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5}>An activity report provides a clear snapshot of a project’s status, offering insights into completed tasks, ongoing work, and upcoming milestones.It outlines individual and team contributions, highlighting who is responsible for specific tasks and what has been accomplished within a given time frame.</Typography>            
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5} pb={5}>By documenting progress, an activity report helps hold team members accountable for their responsibilities and ensures that deadlines are met. With comprehensive data on team performance and project status, activity reports enable managers to make informed decisions and adjust strategies as needed.</Typography>
        </Grid>
    </Grid>
    </>
  )
}

export default ProjectProgress;