import React from 'react';
import Home from './components/pages/Home';
import Layout from './components/layout/Layout';
import LocationTracking from './components/pages/Features/LocationTracking';
import RealTimeProgress from './components/pages/Features/RealTimeProgress';
import ProjectProgress from './components/pages/Features/ProjectProgress';
import AttendanceTracking from './components/pages/Features/AttendanceTracking';
import MultiPersonMonitoring from './components/pages/Features/MultiPersonMonitoring';
import AddressManagement from './components/pages/Features/AddressManagement';
import InstantMessaging from './components/pages/Features/InstantMessaging';
import OfflineMode from './components/pages/Features/OfflineMode';
import Faq from './components/pages/Footer/Faq';
import Login from './components/authentication/Login';
import ManagerPanel from './components/Manager/ManagerPanel';
import ManagerLayout from './components/Manager/components/layout/ManagerLayout';

const routesConfig = [      
    { 
    path: '/',
    element: <Layout/>,
    children: [
        { 
            path: '/',
            element: <Home/> 
        }, 
        { 
            path: '/location-tracking',
            element: <LocationTracking/> 
        },   
        {
            path: '/real-time-progress',
            element: <RealTimeProgress/>
        },
        {
            path: '/project-progress',
            element: <ProjectProgress/>
        },
        {
            path: '/attendance-tracking',
            element: <AttendanceTracking/>
        },
        {
            path: '/multi-person-monitoring',
            element: <MultiPersonMonitoring/>
        },   
        {
            path: '/address-management',
            element: <AddressManagement/>
        },
        {
            path: '/instant-messaging',
            element: <InstantMessaging/>
        },
        {
            path: '/offline-mode',
            element: <OfflineMode/>
        },
        {
            path: '/faqs',
            element: <Faq/>
        },
        {
            path: '/login',
            element: <Login/>
        }
        ]
    },
    { 
        path: '/manager',
        element: <ManagerPanel/>
        // children: [
        //     { 
        //         path: '/dashboard',
        //         element: <ManagerPanel/> 
        //     }, 
        // ]
    }   
];

export default routesConfig;
