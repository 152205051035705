import { Icon } from '@iconify/react/dist/iconify.js'
import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles';

function TestinomialsCard({description, name}) {
    const theme = useTheme();

  return (
    <Card
        sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: "300px",
        height: "300px",
        borderRadius: "50px",
        cursor: "pointer",
        overflow: "hidden",
        transition: "all 800ms ease", 
        background: "none",
        color: theme.palette.common.white,
        "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: "linear-gradient(180deg, #023047 0%, #0085B6 100%)",
            zIndex: 1,
            transition: "all 800ms ease-in-out",
            opacity: 1,
        },
        "&::after": {
            content: '""',
            position: "absolute",
            width: "0%",
            height: "0%",
            backgroundImage: "linear-gradient(180deg, #FFB703 0%, #6C4E01 100%)",
            borderRadius: "50%",
            zIndex: 1,
            opacity: 0,
            transition: "all 800ms ease-in-out",
        },
        "&:hover::before": {
            opacity: 0,
        },
        "&:hover::after": {
            width: "150%",
            height: "150%",
            opacity: 1,         
        },
        "&:hover *": {
                    color: theme.palette.secondary.main,
                    transition: "all 800ms ease-in-out",
                },
        "& > *": {
            position: "relative",
            zIndex: 3, 
            transition: "all 800ms ease-in-out",
        },
        }}
    >    
        <Box sx={{height:'100%'}}>
            <Icon icon="gridicons:quote" style={{fontSize:"80px", textAlign:'left'}}/>
            <Box pl='30px'>
                <Typography fontSize={20} fontFamily='Manrope' sx={{fontWeight: 100}}>{description}</Typography>  
                <Typography fontWeight={800} fontFamily='Manrope' pt={3}>{name}</Typography>    
            </Box>
        </Box>
        
    </Card>
  )
}

export default TestinomialsCard