import React from 'react';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material';

const SpotTextField = ({
  id,
  name,
  type = "text",
  placeholder = "",
  value,
  onChange,
  error = false,
  helperText = "",
  required = false,
  fullWidth = true,
  autoFocus = false,
  autoComplete,
  variant = "outlined",
  sx = {},
  ...props
}) => {
  const theme = useTheme();
  return (
    <TextField
      id={id}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      required={required}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      variant={variant}
      {...props}
      sx={{
        width: "328px",
        height: "40px",               
        '& .MuiOutlinedInput-root': {
          borderRadius: '0px',
          height: "40px",
          color: theme.palette.common.white, 
          borderColor: theme.palette.common.white,
          '& fieldset': { borderColor: theme.palette.common.white },
          '&:hover fieldset': { borderColor: theme.palette.common.white },
          '&.Mui-focused fieldset': { borderColor: theme.palette.common.white },
        },
        '& .MuiInputLabel-root': {
          color: theme.palette.common.white,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: theme.palette.common.white,
        },
        '& .MuiFormHelperText-root': {
          color: theme.palette.common.white,
        },
        '& input': {      
          p:1,
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0px 1000px #023047 inset`,        
            WebkitTextFillColor: theme.palette.common.white    
          },
        },
        ...sx,
      }}
    />
  );
};

export default SpotTextField;
