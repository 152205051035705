import React from 'react'
import Grid from '@mui/material/Grid2';
import Header from '../../layout/Header'
import rtOne from '../../../assets/Features/rt-one.webp'
import rtTwo from '../../../assets/Features/rt-two.webp'
import { Box, Typography } from '@mui/material';

function RealTimeProgress() {
  return (
    <>
    <Header title="Real-Time Progress Monitoring"/>    

    <Grid container alignItems="center" direction="column">
        <Grid item container size={12}>
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${rtOne})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${rtTwo})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
        </Grid>

        <Grid item maxWidth="1110px">           
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5}>A collaboration tracking system provides immediate updates on project status, enabling teams to effectively monitor milestones and deadlines. It facilitates task assignments and monitors individual contributions, ensuring clarity regarding roles and responsibilities, which enhances accountability.</Typography>            
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5} pb={5}>By offering a detailed view of team activities, it fosters transparency, allowing both team members and leaders to assess performance and progress. By optimizing task management, deadlines, and team communications, the system boosts overall productivity, ensuring that collaborative efforts result in successful project outcomes.</Typography>
        </Grid>
    </Grid>
    </>
  )
}

export default RealTimeProgress;