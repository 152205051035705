import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl, FormLabel } from '@mui/material';
import SpotTextField from '../styled/SpotTextField';
import { useTheme } from '@emotion/react';
import SpotButton from '../styled/SpotButton';

function ForgotPassword({ open, handleClose }) {
    const theme = useTheme()
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
        sx: { backgroundImage: 'none' },
      }}
    >
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          Enter your account&apos;s email address, and we&apos;ll send you a link to
          reset your password.
        </DialogContentText>
        <FormControl sx={{pb:2}}>
            <FormLabel htmlFor="email" sx={{color:theme.palette.secondary.main, fontSize:"24px", pb:1, textAlign:"left", fontWeight:"400px"}}>Email:</FormLabel>
            <SpotTextField type='email' sx={{'& .MuiOutlinedInput-root': {         
                color: theme.palette.secondary.main, 
                borderColor: theme.palette.secondary.main,
                '& fieldset': { borderColor: theme.palette.secondary.main },
                '&:hover fieldset': { borderColor: theme.palette.secondary.main },
                '&.Mui-focused fieldset': { borderColor: theme.palette.secondary.main },
                },
                '& .MuiInputLabel-root': {
                color: theme.palette.secondary.main,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                color: theme.palette.secondary.main,
                },
                '& .MuiFormHelperText-root': {
                color: theme.palette.secondary.main,
                },
                '& input': {      
                p:1,
                '&:-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0px 1000px #023047 inset`,        
                    WebkitTextFillColor: theme.palette.secondary.main    
                },
                },}}/>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <SpotButton variant='text' color='error' onClick={handleClose}>Cancel</SpotButton>
        <SpotButton variant="text" type="submit" color='secondary'>
          Continue
        </SpotButton>
      </DialogActions>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;