import React from 'react'
import Grid from '@mui/material/Grid2';
import Header from '../../layout/Header'
import omOne from '../../../assets/Features/om-one.webp'
import omTwo from '../../../assets/Features/om-two.webp'
import { Box, Typography } from '@mui/material';

function OfflineMode() {
  return (
    <>
    <Header title="Instant Messaging"/>    

    <Grid container alignItems="center" direction="column">
        <Grid item container size={12}>
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${omOne})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${omTwo})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
        </Grid>

        <Grid item maxWidth="1110px">                       
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5} pb={5}>Offline mode allows users to access certain app features or files without an internet connection. It is commonly used in apps like maps, music players, and document editors, where data is downloaded beforehand for use when offline. This mode is particularly helpful in areas with poor connectivity or when trying to save data usage. In offline mode, users can still work, read, or view content, and any changes or updates are synced once an internet connection is restored. It enhances flexibility and ensures continued productivity in situations where connectivity is limited or unavailable.</Typography>
        </Grid>
    </Grid>
    </>
  )
}

export default OfflineMode;