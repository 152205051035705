import React from 'react'
import Grid from '@mui/material/Grid2';
import Header from '../../layout/Header'
import aaOne from '../../../assets/Features/aa-one.webp'
import aaTwo from '../../../assets/Features/aa-two.webp'
import { Box, Typography } from '@mui/material';

function AttendanceTracking() {
  return (
    <>
    <Header title="Attendance Tracking"/>    

    <Grid container alignItems="center" direction="column">
        <Grid item container size={12}>
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${aaOne})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${aaTwo})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
        </Grid>

        <Grid item maxWidth="1110px">           
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5}>Monitoring employee attendance is essential for ensuring accountability and adherence to designated work hours. By analyzing attendance trends, managers can evaluate the effect of employee presence on overall productivity and pinpoint opportunities for enhancement.</Typography>            
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5} pb={5}>Maintaining precise attendance records streamlines payroll processes and ensures compliance with labor regulations and organizational policies. Regular tracking through attendance systems can reveal patterns of absenteeism, allowing for prompt actions to mitigate potential concerns.</Typography>
        </Grid>
    </Grid>
    </>
  )
}

export default AttendanceTracking