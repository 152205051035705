import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, useTheme } from '@mui/material';

function BlogCard({ image, title, description }) {
  const theme = useTheme();
  const hoverDescRef = useRef(null);
  const [descHeight, setDescHeight] = useState(0);

  useEffect(() => {
    if (hoverDescRef.current) {
      setDescHeight(hoverDescRef.current.offsetHeight);
    }
  }, [description]);

  return (
    <Card
      elevation={0}
      sx={{
        width: "300px",
        height: "673px",
        overflow: "hidden",
        cursor: "pointer",
        borderBottomLeftRadius: 60,
        borderBottomRightRadius: 60,
        color: theme.palette.secondary.light,
        position: "relative",
        backgroundColor: theme.palette.common.white,
        transition: 'background-color 500ms ease-in-out',
        '&:hover': {
          color: theme.palette.common.white,
        },
        '&:hover .hover-desc': {
          opacity: 1,
          visibility: "visible",
          transform: "translateY(0)",
          transition: "opacity 500ms ease-in-out, transform 500ms ease-in-out",
        },
        '&:hover .hover-media': {
          transform: `translateY(-${descHeight}px)`,
          borderBottomLeftRadius: 60,
          borderBottomRightRadius: 60,
          transition: "all 500ms ease-in-out",
        },
        '&:hover .hover-content': {
          transform: `translateY(-${descHeight}px)`,
          transition: "all 500ms ease-in-out",
        },
        '.hover-desc': {
          opacity: 0,
          visibility: "hidden",
          transform: "translateY(20px)",
          position: "absolute",
          textAlign: "center",
          padding: "8px 16px",
          color: theme.palette.common.white,
          borderRadius: "8px",
          boxSizing: "border-box",
          transition: "opacity 500ms ease-in-out, transform 500ms ease-in-out",
        },
        '.hover-media': {
          borderRadius: 0,
          transform: "translateY(0)",
          transition: "all 500ms ease-in-out",
        },
        '.hover-content': {
          transform: "translateY(0)",
          transition: "all 500ms ease-in-out",
          position: "relative",
        },

        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(180deg, #ffffff 0%, #023047 100%)',
          opacity: 0,
          transition: 'opacity 800ms ease-in-out',
          zIndex: 0,
        },
        '&:hover::before': {
          opacity: 1,
        },
      }}
    >
      <CardMedia
        className="hover-media"
        sx={{ height: "474px", width: "300px" }}
        image={image}
        title="blog-image"
      />
      <CardContent
        className="hover-content"
        sx={{
          pl: 1,
          pr: 1,
          width: "300px",
        }}
      >
        <Typography
          textAlign="center"
          fontSize="32px"
          fontWeight="400"
          lineHeight="40px"
        >
          {title}
        </Typography>

        <Typography
          className="hover-desc"
          ref={hoverDescRef}
          textAlign="center"
          fontSize="20px"
          fontWeight="400"
          lineHeight="20px"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default BlogCard;
