import React from 'react';
import Grid from '@mui/material/Grid2';
import bgOne from '../../../../assets/bg-one.webp'
import bgTwo from '../../../../assets/bg-two.webp'
import bgThree from '../../../../assets/bg-three.webp'
import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import FeaturesCard from './FeaturesCard';
import { Icon } from '@iconify/react';
import TestinomialsCard from './TestinomialsCard';


const Features = [
  {
    icon: () => <Icon icon="mdi:map-marker" style={{fontSize:'100px'}}/>, 
    title: "Location Tracking", 
    description: "Track your field force's location for effective management and safety",
    route: 'location-tracking'
  },
  {
    icon: () => <Icon icon="typcn:time" style={{fontSize:'100px'}}/>, 
    title: "Real-Time Progress Monitoring", 
    description: "Increase productivity with a team collaboration tracking system",
    route: 'real-time-progress'
  }, 
  {
    icon: () => <Icon icon="lets-icons:time-progress-duotone" style={{fontSize:'100px'}}/>, 
    title: "Project Progress Overview", 
    description: "Activity reports summarize project status and key milestones",
    route: 'project-progress'
  },
  {
    icon: () => <Icon icon="fluent:my-location-20-filled" style={{fontSize:'80px'}}/>, 
    title: "Attendance Tracking", 
    description: "Increase productivity with a team collaboration tracking systemAttendance tracking ensures accountability and productivity",
    route: 'attendance-tracking'
  }, 
  {
    icon: () => <Icon icon="fa-solid:user-friends"  style={{fontSize:'80px'}} />, 
    title: "Multi- person Monitoring", 
    description: "Observes team activities for collective performance insights",
    route: 'multi-person-monitoring'
  },
  {
    icon: () => <Icon icon="fa6-regular:address-card"  style={{fontSize:'80px'}} />, 
    title: "Address Management", 
    description: "Organizes and maintains address information for easy access",
    route: 'address-management'
  }, 
  {
    icon: () => <Icon icon="icon-park-solid:message"  style={{fontSize:'80px'}} />, 
    title: "Instant Messaging", 
    description: "Allows quick, real-time text communication on digital platforms",
    route: 'instant-messaging'
  },
  {
    icon: () => <Icon icon="ion:cloud-offline"  style={{fontSize:'80px'}} />, 
    title: "Offline Mode", 
    description: "Enables offline access to app features or files without internet",
    route: 'offline-mode'
  },  
];

const Testinomials = [
  {
    description: "Spot My Team has transformed our sales tracking—real-time analytics boost our productivity!", 
    name: "-Alex T., Sales Manager",     
  },

  {
    description: "The app keeps our team aligned and motivated. It’s like having a personal sales coach!", 
    name: "-Maria L., Sales Director",     
  },

  {
    description: "Sales increased by 30% since we started using Spot My Team. The insights are invaluable!", 
    name: "-Jake R., Business Owner",     
  },
]


function Main() {
  const theme = useTheme();

  return (<>  
     <Grid container justifyContent="center" sx={{pt:"110px", position: 'relative', minHeight: '400vh', overflow: 'hidden',}}>      
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%',  height: "100vh", backgroundImage: `url(${bgOne})`, backgroundSize: 'cover', backgroundPosition: 'center', zIndex: -1,}}/>       
        <Box sx={{ position: 'absolute', top: '100vh', left: 0, width: '100%', height: "200vh", backgroundImage: `url(${bgTwo})`, backgroundSize: 'cover', backgroundPosition: 'center', zIndex: -1,}}/>        
        <Box sx={{ position: 'absolute', top: '300vh', left: 0, width: '100%', height: {sm:"800vh",md:"300vh"}, backgroundImage: `url(${bgThree})`, backgroundSize: 'cover', backgroundPosition: 'center', zIndex: -1,}}/>        
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#0230477A', backdropFilter: 'blur(10px)', zIndex: 0,}}/>

        <Grid item container spacing={4} size={12} direction="column" sx={{ zIndex: 1, pl:"162px", pr:"162px", display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', height: 'auto', color: 'white', }}>
          <Grid item width="583px" size={12} height="225px">
            <Typography sx={{fontSize: '64px',  fontWeight: 500, lineHeight: '75px', textAlign: 'left'}}>
              Transform your field workforce with<br/>Spot My Team
            </Typography>        
          </Grid>

          <Grid item size={12}>
            <Typography sx={{fontSize: '24px',  fontWeight: 400, lineHeight: '28.13px', textAlign: 'left'}}>
              Made the Field management effective with our Spot My Team Automation App. Increase Productivity with Automated Workflow, Streamline operations, and Real-Time Activities.
            </Typography>        
          </Grid>

          <Grid item size={12}>
          <Button variant='contained' sx={{width:"150px", height:"50px", borderRadius: '10px', '&:hover': { backgroundColor: '#ffffff', borderRadius: '30px' }, fontSize:"24px", fontWeight: 'bold', transition: 'all 0.3s ease', textTransform: "none",}}>
            Register
          </Button>
          </Grid>
        </Grid>             

        
        <Grid item container spacing={6} size={7} direction="row" sx={{mt:10, zIndex: 1, display: 'flex', justifyContent: "center", color: 'white', }}>
          <Grid item size={12}>
          <Box id="features-section">
            <Typography fontSize="64px" textAlign="center">Features</Typography>
            <Typography fontSize="24px" textAlign="center">Discover the features that will simplify and optimize your field operations.</Typography>
          </Box>
          </Grid>

          {Features.map((item, index) => (
            <Grid item container justifyContent="center" size={6}>
              <FeaturesCard 
                key={index}
                Icon={item.icon}                
                title={item.title}
                description={item.description}
                route={item.route}
              />
            </Grid>
          ))}
        </Grid>   

        <Grid item container spacing={6} direction="row" sx={{mt:10, pb:10, zIndex: 1, display: 'flex', justifyContent: "center", alignItems: 'center', color: 'white', }}>
          <Grid item size={12}>
            <Typography fontSize="64px" textAlign="center" pb={3}>Testimonials</Typography>
            <Typography fontSize="24px" textAlign="center">Discover the insights and experiences shared by our valued customers.</Typography>            
          </Grid>

          {Testinomials.map((item, index) => (
            <Grid item>
              <TestinomialsCard
                key={index}
                description={item.description}
                name={item.name}
              />
          </Grid>
        ))}
        </Grid>                                         
      </Grid>        
    </>
  )
}

export default Main 