import React from 'react'
import Main from './components/Home/Main' 
import Blogs from './components/Home/Blogs'

function Home() {
  return (
    <>
    <Main/>
    <Blogs/>
    </>
  )
}

export default Home