import React from 'react'
import Grid from '@mui/material/Grid2';
import Header from '../../layout/Header'
import aaOne from '../../../assets/Features/aa-one.webp'
import aaTwo from '../../../assets/Features/aa-two.webp'
import { Box, Typography } from '@mui/material';

function AddressManagement() {
  return (
    <>
    <Header title="Address Management"/>    

    <Grid container alignItems="center" direction="column">
        <Grid item container size={12}>
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${aaOne})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${aaTwo})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
        </Grid>

        <Grid item maxWidth="1110px">           
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5}>Address management entails the systematic organization and upkeep of address information, facilitating easy access and retrieval for various applications.
            By maintaining accurate and current address details, businesses can improve their interactions with customers, ensuring prompt deliveries and timely notifications.</Typography>            
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5}>Well-maintained address databases support targeted marketing efforts, enabling businesses to effectively connect with their audience and boost engagement levels. Proper address management practices assist organizations in adhering to legal and regulatory standards concerning data management and privacy leading to strategic and informed decision-making .Contemporary address management systems can seamlessly integrate with CRM and ERP solutions, improving overall business efficiency and workflow.</Typography>
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5} pb={5}>Ultimately, effective address management plays a crucial role in elevating customer service, ensuring that customers receive their orders and communications promptly.</Typography>
        </Grid>
    </Grid>
    </>
  )
}

export default AddressManagement