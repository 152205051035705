import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import loginBg from '../../assets/login-bg.webp'
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Link, TextField, Typography, useTheme } from '@mui/material';
import SpotTextField from '../styled/SpotTextField';
import ForgotPassword from './ForgotPassword';
import SpotButton from '../styled/SpotButton';

function Login() {
    const theme = useTheme();
    const [rememberMe, setRememberMe] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleChange = (event) => {
      setRememberMe(event.target.checked);
    };

  return (
    <Grid container height="140vh">
        <Grid item size={6}>
            <Box sx={{width: '100%', height: '140vh', backgroundImage: `url(${loginBg})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
        </Grid>

        <Grid item container size={6} alignItems="center" justifyContent="center">
            <Grid item container direction="column" width="550px" height="560px" justifyContent="center" textAlign="center" alignItems="center" sx={{bgcolor:theme.palette.secondary.main, borderRadius:"20px"}}>              

                <Typography color='common.white' textAlign="center" fontSize="40px" fontWeight="400px" pb={6}>LOG IN</Typography>

                <Box component="form" noValidate sx={{display:"flex", flexDirection:"column", alignItems:"center" }}>
                  <FormControl sx={{pb:2}}>
                    <FormLabel htmlFor="email" sx={{color:theme.palette.common.white, fontSize:"24px", pb:1, textAlign:"left", fontWeight:"400px"}}>Email or Username:</FormLabel>
                    <SpotTextField type='email'/>
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="password" sx={{color:theme.palette.common.white, fontSize:"24px", pb:1, textAlign:"left", fontWeight:"400px"}}>Password:</FormLabel>
                    <SpotTextField type='password'/>
                  </FormControl>   
                  
                  <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" pb={4}>
                    <FormControl>
                      <FormControlLabel
                      label="Remember Me"
                      labelPlacement="start"
                      control={
                        <Checkbox
                          disableRipple
                          checked={rememberMe}
                          onChange={handleChange}
                          sx={{
                            color: theme.palette.common.white,
                            '&.Mui-checked': {
                              color: theme.palette.common.white,
                            },
                          }}
                        />
                      }
                      sx={{color:theme.palette.common.white,  m:0}}
                    />
                  </FormControl>

                  <Typography onClick={handleClickOpen} sx={{cursor:"pointer", color:theme.palette.common.white, fontSize:"16px"}}>Forgot Password?</Typography>
                  <ForgotPassword open={open} handleClose={handleClose}/>                  
                 </Box>

                  <SpotButton disableRipple type="submit" variant="contained" color="primary" sx={{bgcolor:theme.palette.common.white,fontSize:"24px", width:"150px", height:"50px", borderRadius:"20px"}}>Log in</SpotButton>             
                </Box>                                                      
            </Grid>
        </Grid>
    </Grid>
  )
}

export default Login