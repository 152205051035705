import React from 'react';
import { Card, CardMedia, CardContent, Typography, Box} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

function FeaturesCard({ Icon, title, description, route }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick =()=>{
    navigate(`/${route}`)
  }

  return (
    <Card onClick={handleClick} sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", bgcolor:"#FFFFFF87", width:"400px", height:"260px", borderRadius:"10px", cursor:"pointer", '&:hover': {bgcolor: theme.palette.secondary.main, '& .MuiTypography-root': { color: theme.palette.common.white,}, '& .MuiSvgIcon-root': { color: theme.palette.primary.main, }, '& .icon-container': { color: theme.palette.primary.main,},}, transition: "all 500ms ease"}}>            
        <CardMedia>
        <Box
          className="icon-container"           
          sx={{
            color: theme.palette.secondary.main, 
            transition: "color 500ms ease",
          }}
        >
          <Icon />
        </Box>
        </CardMedia>
        <CardContent sx={{pl:1, pr:1, transition: "all 500ms ease"}}>
          <Typography color='text.secondary' textAlign="center" fontWeight="bold" pb="10px">{title}</Typography>
          <Typography color='text.secondary' textAlign="center">{description}</Typography>
        </CardContent>
    </Card>
  );
}


export default FeaturesCard;