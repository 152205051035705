import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {

        common:{
            black: "#000000",
            white: "#ffffff",
        },

        primary: {
        main: "#FFB703",
        contrastText: "#023047"
        },

        secondary: {
            main: "#023047",
        },

        background:{
            default: "#ffffff"
        },

        text:{
            primary: "#023047",
            secondary: "#000000"            
        },
    },
    
    typography: {
        fontFamily: "'Roboto', 'Manrope'",
    }
  });

export default theme;
