import React from 'react';
import { Button } from '@mui/material';
import { Box, styled } from '@mui/system';

const AnimatedButton = styled(Button)`
  position: relative;
  overflow: hidden;
  width: auto;  
  min-width: 150px;
  height: 50px;   
  color:#023047;
//   background-color: #6200ea;
  font-weight: 600;
  text-transform: none;
  transition: background-color 500ms ease;
  padding: 0;    
  display: flex;
  justify-content: center; 
  align-items: center;     
  border-radius: 8px;
  font-size: 24px;  
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    background-color: #ffffff00;
  }
  
  .text-primary {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(10%); 
    color: #023047;
    transition: transform 500ms ease-in-out;
    display: flex;
    justify-content: center;  
    align-items: center;   
  }

  .text-hover {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(200%); 
    color:#FFB703;
;
    transition: transform 500ms ease-in-out;
    display: flex;
    justify-content: center; 
    align-items: center;     
  }

  &:hover .text-primary {
    transform: translateX(-50%) translateY(-100%); 
  }

  &:hover .text-hover {
    transform: translateX(-50%) translateY(10%); 
  }
`;

export default function AnimatedButtonComponent({ children, onClick, sx = {}, ...props }) {
  return (
    <AnimatedButton disableRipple onClick={onClick} sx={{...sx}} {...props}>      
      <Box className="text-primary">{children}</Box>
      <Box className="text-hover">{children}</Box>
    </AnimatedButton>
  );
}
