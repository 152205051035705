import React from 'react'
import headerBg from '../../assets/header-bg.webp'
import Grid from '@mui/material/Grid2';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import { common } from '@mui/material/colors';

function Header({title}) {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
      };

  return (
    <Grid container sx={{ position: 'relative', height: '200px', overflow: 'hidden'}}>      
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '200px', backgroundImage: `url(${headerBg})`, backgroundSize: 'cover', backgroundPosition: 'center', zIndex: -1,}}/>        
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#000000A6', backdropFilter: 'blur(10px)', zIndex: 0,}}/>

        <Grid item container mt="100px" width="100%" alignItems="center" sx={{zIndex:1}}>
            <Grid item size={2}>
                <Box onClick={handleBack} sx={{ cursor: 'pointer', display:'flex', alignItems:'center', justifySelf:'center' }}>
                    <Icon icon="eva:arrow-ios-back-outline"  style={{color: common.white, fontSize:'70px'}} />
                </Box>
            </Grid>

            <Grid item size={8} sx={{zIndex:1}}>
                <Typography color='common.white' fontSize="48px" textAlign="center" fontWeight="600px">{title}</Typography>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default Header;


