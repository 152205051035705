import React from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { Icon } from '@iconify/react';
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = (route) =>{
    navigate(`/${route}`)
  }

  return (
    <Box sx={{ backgroundColor: '#003c58', color: 'white'}}>
      <Grid container direction="row" sx={{height:"318px"}}> 
        <Grid item container direction="row" justifyContent="center" alignItems="center" size={5.75}>
          <Grid item container direction="column" spacing={3} pr={15}>
            <Button variant="text" disableRipple sx={{justifyContent: "flex-start", color:"common.white",textTransform: 'none', fontSize: '24px', fontWeight: "600px",  "&:hover": {backgroundColor: "transparent"},}}>
              About Us
            </Button>
            <Button variant="text" disableRipple sx={{justifyContent: "flex-start", color:"common.white",textTransform: 'none', fontSize: '24px', fontWeight: "600px",  "&:hover": {backgroundColor: "transparent"},}}>
              Blog
            </Button>
            <Button variant="text" disableRipple sx={{justifyContent: "flex-start", color:"common.white",textTransform: 'none', fontSize: '24px', fontWeight: "600px",  "&:hover": {backgroundColor: "transparent"},}}>
              Presentation
            </Button>
          </Grid>

          <Grid item container direction="column" spacing={3}>
          <Button onClick={()=>{handleNavigate("faqs")}} variant="text" disableRipple sx={{justifyContent: "flex-start", color:"common.white",textTransform: 'none', fontSize: '24px', fontWeight: "600px",  "&:hover": {backgroundColor: "transparent"},}}>
              FAQs
            </Button>
            <Button variant="text" disableRipple sx={{justifyContent: "flex-start", color:"common.white",textTransform: 'none', fontSize: '24px', fontWeight: "600px",  "&:hover": {backgroundColor: "transparent"},}}>
            Privacy Policy
            </Button>
            <Button variant="text" disableRipple sx={{justifyContent: "flex-start", color:"common.white",textTransform: 'none', fontSize: '24px', fontWeight: "600px",  "&:hover": {backgroundColor: "transparent"},}}>
            Terms of Use
            </Button>
          </Grid>
        </Grid>

        <Grid item container justifyContent="center" size={0.5}>
          <Divider orientation="vertical" sx={{width: '9px', bgcolor: '#ffffff',}}/>
        </Grid>
        
        <Grid item  container size={5.75} direction="column" justifyContent="center" alignItems="center">
            <Grid item pb={3}>              
              <Typography fontSize="36px" fontWeight="600px">Contact Us</Typography>
            </Grid>

            <Grid item container spacing={4}>
              <Link>
                <Icon icon="bxl:instagram-alt"  style={{color: theme.palette.common.white, fontSize:'46px'}} />
              </Link>

              <Link>
                <Icon icon="lineicons:facebook"  style={{color: theme.palette.common.white, fontSize:'46px'}} />
              </Link>

              <Link>
                <Icon icon="ri:twitter-x-fill"  style={{color: theme.palette.common.white, fontSize:'46px'}} />
              </Link>

              <Link>
                <Icon icon="mdi:linkedin"  style={{color: theme.palette.common.white, fontSize:'46px'}} />
              </Link>
            </Grid>
        </Grid>
        
      </Grid>

      <Box>
        <Typography align='center'>Powered by
          <Box component={Link} to="https://www.keelis.com" target='_label'
            sx={{
              textDecoration:"underline",
            color: '#ffffff',
              display: 'inline'

            }}
          > keelis</Box>
        </Typography>
        <Typography align='center'>© {new Date().getFullYear()}, Spot My Team All Rights Reserved.</Typography>              
      </Box>  
    </Box>
  )
}

export default Footer