import React from 'react'
import Header from '../../layout/Header'
import Accordion, { accordionClasses } from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails, { accordionDetailsClasses } from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import { Fade, Typography } from '@mui/material';

const faqs = [
    {
        question:"What is Spot My Team?",
        answer:"Spot My Team is the Sales Tracking System is a platform designed to monitor salespersons' activities in real time. It allows salespersons to log their attendance, track locations, and record their daily activities, while managers can monitor and manage sales teams across various regions using a map-based interface."
    },
    {
        question:"What is the main objective of Spot My Team?",
        answer:"The system aims to: Enable salespersons to log attendance and daily activities. Provide real-time visibility of salesperson location and activities to managers. Offer tools for managers to efficiently monitor multiple salespersons and regions. Allow managers to access reports and notes added by salespersons for analysis."
    },
    {
        question:"What features are available for salespersons in Spot My Team?",
        answer:"Check in and check out daily to log attendance.Add and edit detailed notes after each visit, including meeting details, outcomes, and attachments.View reminders and notifications set by their managers for upcoming meetings."
    },
    {
        question:"How do salespersons log their attendance?",
        answer:"Salespersons check in at the start of their workday and check out at the end using the system. Their check-in and check-out times, as well as their locations, are tracked in real-time."
    },
    {
        question:"Can salespersons view their past attendance records?",
        answer:"Yes, salespersons can view their attendance history, including past check-ins and check-outs, within the system."
    },
    {
        question:"How can managers track the location of salespersons?",
        answer:"Managers can view real-time locations of salespersons on a live map. The system uses GPS to display the salesperson's check-in and check-out locations and shows their route throughout the day."
    },
    {
        question:"Can managers manage salespersons by region?",
        answer:"Yes, managers can view and manage multiple salespersons by region, assign or reassign them to different regions as needed, and monitor their activities."
    },
    {
        question:"What information is displayed when a manager clicks on a salesperson’s location on the map?",
        answer:"When a manager clicks on a salesperson’s location, a detailed timeline of the salesperson’s activities is shown, including the places visited and a visual representation of the route taken during the day."
    },
    {
        question:"Are salespersons' notes visible to managers?",
        answer:"Yes, managers can view all notes added by salespersons, organized by visit and region. These notes provide insights into each salesperson's activities and outcomes."
    },
    {
        question:"How does Spot My Team enhance sales team management?",
        answer:"The system provides real-time tracking, transparent check-ins, and detailed reporting. Managers can monitor their teams more efficiently, optimize performance, and improve communication, ultimately leading to better sales results."
    },
    {
        question:"What are the benefits of using Spot My Team?",
        answer:" The benefits include improved sales performance tracking, streamlined management of salespersons across regions, real-time activity visibility, and enhanced reporting and analysis capabilities for managers."
    }
]


function Faq({question, answer}) {

    const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = (panel) => {    
    setExpanded(expanded === panel ? false : panel);
  };

  return (      
    <>
      <Header title="FAQs" />
      <Grid container width="100%" justifyContent="center" pt={10} pb={10}>
        <Grid item maxWidth="1069px">
          {faqs.map((faq, index) => (
            <Accordion key={index} expanded={expanded === index} onChange={() => handleExpansion(index)} sx={{ marginBottom: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>                
                {Array.isArray(faq.answer) ? (
                  <ul>
                    {faq.answer.map((item, idx) => (
                      <li key={idx}>
                        <Typography>{item}</Typography>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography>{faq.answer}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default Faq