import React from "react";
import {
  Box,  
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DownloadIcon from "@mui/icons-material/Download";
import BarChartIcon from "@mui/icons-material/BarChart";
import Grid from '@mui/material/Grid2';

function Dashboard() {
  return (
    <Box display="flex" height="100vh">
      {/* Sidebar */}
      <Box
        sx={{
          width: "80px",
          bgcolor: "#002B49",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent:"center",
          paddingY: 2,
        }}
      >
        <IconButton sx={{ color: "white", marginBottom: 4 }}>
          <MenuIcon />
        </IconButton>
        <IconButton sx={{ color: "white", marginBottom: 4 }}>
          <BarChartIcon />
        </IconButton>
        {/* Add more icons here */}
      </Box>

      {/* Main Content */}
      <Box flex={1} bgcolor="#F5F7FA" padding={4}>
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={4}
        >
          <Typography variant="h5" color="primary">
            <img src="logo.png" alt="Logo" style={{ height: "50px" }} />
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Button variant="outlined" sx={{ textTransform: "none" }}>
              Manager
            </Button>
            <Button variant="contained" sx={{ textTransform: "none" }}>
              KPI Tracker
            </Button>
            <Button
              variant="text"
              startIcon={<DownloadIcon />}
              sx={{ textTransform: "none" }}
            >
              Download Reports
            </Button>
          </Box>
        </Box>

        {/* KPI Cards */}
        <Grid container spacing={2} marginBottom={4}>
          {[
            { title: "Sales Target (This Month):", value: "₹2,00,000" },
            { title: "Achieved (This Month):", value: "₹1,20,000" },
            { title: "Remaining Target (This Month):", value: "₹80,000" },
          ].map((item, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card
                sx={{
                  bgcolor: "#003A63",
                  color: "white",
                  textAlign: "center",
                  borderRadius: 2,
                }}
              >
                <CardContent>
                  <Typography variant="body1">{item.title}</Typography>
                  <Typography variant="h6" fontWeight="bold">
                    {item.value}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Charts and Expenses */}
        <Grid container spacing={2}>
          {/* Chart */}
          <Grid item xs={12} sm={6}>
            <Card sx={{ borderRadius: 2, padding: 2 }}>
              <Typography variant="h6" marginBottom={2}>
                Expenses Overview
              </Typography>
              {/* Add your chart component here */}
              <Box
                sx={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "#EDEDED",
                  borderRadius: 2,
                }}
              >
                Chart Placeholder
              </Box>
            </Card>
          </Grid>

          {/* Logged Expenses */}
          <Grid item xs={12} sm={6}>
            <Card sx={{ borderRadius: 2, padding: 2 }}>
              <Typography variant="h6" marginBottom={2}>
                Total Logged Expenses (Today):
              </Typography>
              {[
                "Travel (Taxi to Client Site): ₹800",
                "Meals (Lunch with Mr. Sharma): ₹1,200",
                "Parking (XYZ Ltd.): ₹500",
                "Fuel (Vehicle Refill): ₹500",
              ].map((expense, index) => (
                <Typography key={index} variant="body2" marginBottom={1}>
                  {expense}
                </Typography>
              ))}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Dashboard;
