import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routesConfig from './RoutesConfig';
import theme from './components/ui/Theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import '@fontsource/roboto/400.css';
import { useTheme } from '@mui/material/styles';
import ScrollToTop from './components/layout/ScrollToTop';



const renderRoutes = (routes) =>
  routes.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      element={route.element}
    >
      {route.children && renderRoutes(route.children)}
    </Route>
  ));

function App() {  

  return (    
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <ScrollToTop/>
        <Routes>{renderRoutes(routesConfig)}</Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
