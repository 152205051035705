import React from 'react'
import Grid from '@mui/material/Grid2';
import Header from '../../layout/Header'
import ltOne from '../../../assets/Features/lt-one.webp'
import ltTwo from '../../../assets/Features/lt-two.webp'
import { Box, Typography } from '@mui/material';

function LocationTracking() {
  return (
    <>
    <Header title="Location Tracking"/>    

    <Grid container alignItems="center" direction="column">
        <Grid item container size={12}>
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${ltOne})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${ltTwo})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
        </Grid>

        <Grid item maxWidth="1110px">
            <Typography textAlign="center" color='secondary.light' fontSize="20px" fontWeight={700} lineHeight="30px" pt={5}>MONITOR YOUR FIELD FORCE'S LOCATION</Typography>
            <Typography textAlign="center" color='common.black' fontSize="20px" pt={5}>Spot My Team provides you with all the necessary information to oversee the productivity of your field and sales teams throughout the day. Immediate access to data enables businesses to swiftly identify issues and implement solutions, thereby reducing potential costs that could arise from prolonged problems. GPS trackers facilitate the digitization of operations and enhance data collection, guaranteeing that accurate information is gathered and stored centrally for easy access by all team members. By digitizing tasks such as pre-trip inspections, job dispatch, and proof of delivery, fleets can optimize administrative workflows, allowing their teams to concentrate on completing their work efficiently.</Typography>
            <Typography textAlign="center" color='secondary.light' fontSize="20px" fontWeight={700} pt={5}>Stay Updated on Your Team's Activities – All Day Long</Typography>
            <Typography textAlign="center" color='common.black' fontSize="20px" pt={5} pb={5}>The My Team section in Spot My Team allows you to access the current location of each member of your field force, along with essential details about their daily schedules and achievements. The admin can also review their planned visits and meetings, activities logged during their fieldwork, and expense claims, all linked to their respective field activities. This comprehensive information enables the admins to swiftly and effectively allocate idle resources to productive tasks, such as visiting nearby old accounts.</Typography>
        </Grid>
    </Grid>
    </>
  )
}

export default LocationTracking;