import React from 'react';
import BlogCard from './BlogCard';
import blogOne from '../../../../assets/blog-one.webp'
import blogTwo from '../../../../assets/blog-two.webp'
import blogThree from '../../../../assets/blog-three.webp'
import blogFour from '../../../../assets/blog-four.webp'
import {Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';

const allBlogs = [
    {
      image: blogOne,
      title:  'Maximizing Sales Performance with Data-Driven Decisions',
      description: 'Discuss how sales analytics can inform strategies and improve team performance.'
    },
    {
      image: blogTwo,
      title:  'Top 5 Sales Metrics Every Team Should Track',
      description: 'Highlight essential metrics that can help teams assess their performance effectively.'
    },
    {
      image: blogThree,
      title:  'Success Stories: How Companies Transformed Their Sales Processes',
      description: 'Showcase case studies of businesses that improved their sales using Spot My Team.'
    },
    {
      image: blogFour,
      title:  'How to Set Effective Sales Goals for Your Team',
      description: 'Offer strategies for creating achievable and motivating sales targets.'
    }
  ]

function Blogs() {
    const theme = useTheme();

    return (
        <Grid container direction="row" justifyContent="flex-start" pb={6} pt={3}>
            <Grid item size={12}>
                <Typography textAlign="center" fontSize="64px" fontWeight={500} pb={3}>Blogs</Typography>
            </Grid>

            <Grid item container size={12}>
              {allBlogs.map((blog, index) => (
                <Grid item container key={index} justifyContent="center" size={{xs:12, sm:12, md:6, lg:3, xl:3  }}>
                  <BlogCard              
                    image={blog.image}
                    title={blog.title}
                    description={blog.description}                
                  />
                </Grid>          
              ))}            
            </Grid>    
        </Grid>
    );
}

export default Blogs;
