import React from 'react'
import Grid from '@mui/material/Grid2';
import Header from '../../layout/Header'
import imOne from '../../../assets/Features/im-one.webp'
import imTwo from '../../../assets/Features/im-two.webp'
import { Box, Typography } from '@mui/material';

function InstantMessaging() {
  return (
    <>
    <Header title="Instant Messaging"/>    

    <Grid container alignItems="center" direction="column">
        <Grid item container size={12}>
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${imOne})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
            <Box sx={{width: '50%', height:"391px", backgroundImage: `url(${imTwo})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>       
        </Grid>

        <Grid item maxWidth="1110px">           
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5}>Instant messaging serves as a rapid, real-time communication method via text on digital platforms. It enables users to exchange messages without delay, frequently incorporating features such as emojis, voice messages, and media sharing. This mode of communication is widely utilized for both personal and professional interactions, accommodating individual chats as well as group discussions.</Typography>                        
            <Typography textAlign="center" color='secondary.light' fontSize="20px" pt={5} pb={5}>Notable platforms like WhatsApp, Telegram, and Slack provide smooth connectivity across various devices. Given its efficiency and immediacy, instant messaging has emerged as a vital resource for maintaining connections in our fast-moving digital landscape. Nonetheless, it also brings forth issues related to privacy and security.</Typography>
        </Grid>
    </Grid>
    </>
  )
}

export default InstantMessaging